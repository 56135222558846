// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-article-201901-believe-possibilities-of-the-web-js": () => import("/opt/build/repo/src/pages/article/201901/believe-possibilities-of-the-web.js" /* webpackChunkName: "component---src-pages-article-201901-believe-possibilities-of-the-web-js" */),
  "component---src-pages-article-201901-graduate-barbarian-programming-js": () => import("/opt/build/repo/src/pages/article/201901/graduate-barbarian-programming.js" /* webpackChunkName: "component---src-pages-article-201901-graduate-barbarian-programming-js" */),
  "component---src-pages-article-201901-ksyunnnn-js": () => import("/opt/build/repo/src/pages/article/201901/ksyunnnn.js" /* webpackChunkName: "component---src-pages-article-201901-ksyunnnn-js" */),
  "component---src-pages-article-201901-my-view-on-javascript-frameworks-js": () => import("/opt/build/repo/src/pages/article/201901/my-view-on-javascript-frameworks.js" /* webpackChunkName: "component---src-pages-article-201901-my-view-on-javascript-frameworks-js" */),
  "component---src-pages-article-201901-read-your-foreign-book-js": () => import("/opt/build/repo/src/pages/article/201901/read-your-foreign-book.js" /* webpackChunkName: "component---src-pages-article-201901-read-your-foreign-book-js" */),
  "component---src-pages-article-201901-similarities-between-literature-and-javascript-js": () => import("/opt/build/repo/src/pages/article/201901/similarities-between-literature-and-javascript.js" /* webpackChunkName: "component---src-pages-article-201901-similarities-between-literature-and-javascript-js" */),
  "component---src-pages-article-201901-strum-javascript-js": () => import("/opt/build/repo/src/pages/article/201901/strum-javascript.js" /* webpackChunkName: "component---src-pages-article-201901-strum-javascript-js" */),
  "component---src-pages-articles-js": () => import("/opt/build/repo/src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

